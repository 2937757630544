import "./static/css/destyle.css";
import "./static/css/style.css";
import "./static/css/lightcase.css";
export const onInitialClientRender = () => {
  window.onload = () => {
    // JavaScript Document

    // ヘッダーの高さ分下げる
    $(window).on("load resize", function () {
      var height = $("header").height();
      $(".spNav-wrap").css("padding-top", height + 20);
      $("main").css("padding-top", height + 30);
    });

    $(".menuBtn").click(function () {
      $(this).toggleClass("active");

      if ($(this).hasClass("active")) {
        //$('body,html').css({"overflow":"hidden","height":"100%"});
        $(".spNav-wrap").addClass("active");
      } else {
        //$('body,html').css({"overflow":"visible","height":"auto"});
        $(".spNav-wrap").removeClass("active");
      }
    });

    // scroll
    $('a[href^="#"]').click(function () {
      var speed = 500;
      var href = $(this).attr("href");
      var target = $(href == "#" || href == "" ? "html" : href);
      var position = target.offset().top;
      $("html, body").animate({ scrollTop: position }, speed, "swing");
      return false;
    });

    $(".inview").on("inview", function (event, isInView) {
      if (isInView) {
        $(this).stop().addClass("anime");
      }
    });

    //カレントを追加
    $(".map-nav li a").each(function () {
      var $href = $(this).attr("href");
      if (location.href.match($href)) {
        $(this).addClass("current");
      } else {
        $(this).removeClass("current");
      }
    });

    // プロローグの開閉
    $(".show_more2").click(function () {
      var show_text = $(this).parent(".pro_wrapper").find(".pro_text");
      var small_height = 90; //This is initial height.
      var original_height = show_text
        .css({
          height: "auto",
        })
        .height();

      if (show_text.hasClass("open")) {
        /*CLOSE*/
        show_text.height(original_height).animate(
          {
            height: small_height,
          },
          300
        );
        show_text.removeClass("open");
        $(this).text("+ 続きを読む").removeClass("actives");
      } else {
        /*OPEN*/
        show_text.height(small_height).animate(
          {
            height: original_height,
          },
          300,
          function () {
            show_text.height("auto");
          }
        );
        show_text.addClass("open");
        $(this).text("- 閉じる").addClass("actives");
      }
    });
  };
};
